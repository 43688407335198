import * as React from 'react'
import classNames from 'classnames'
import { compose } from 'recompose'
import { withFirebase, withFirestore } from 'react-redux-firebase'
import { UserFormatter } from '../utils/formatters'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import SideMenu from './SideMenu'
import LoginPage from './LoginPage'
import AppToolbar from '../components/AppToolbar'
import Notifications from './Notifications'
import Page from './Page'
import { first } from 'lodash'
import connect from 'react-redux/es/connect/connect'
import StartProvider from '../components/StartProvider'

const pkg = require('../../package.json')

const drawerWidth = 240
const notificationWidth = 240
const footer = 32

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    minWidth: 800,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    minWidth: 0 // So the Typography noWrap works
  },
  appBarSpacer: {
    height: 70,
    ...theme.mixins.toolbar
  },
  spacer: {
    flex: '1 1 100%'
  },
  appBar: {
    // backgroundColor: 'transparent',
    backgroundColor: theme.palette.background.default,
    height: 70,
    paddingTop: 6,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    marginRight: notificationWidth,
    width: `calc(100% - ${drawerWidth}px - ${notificationWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  appBarShiftNotify: {
    width: `calc(100% - ${notificationWidth}px)`
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    padding: 0,
    margin: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0
    }
  },
  footer: {
    position: 'absolute',
    textAlign: 'center',
    bottom: 0,
    height: footer,
    width: '100%',
    padding: 0
  }
})

class App extends React.Component {
  constructor (props) {
    super(props)

    this.handleSignInClick = this.handleSignInClick.bind(this)
    this.handleSignInClose = this.handleSignInClose.bind(this)
    this.handleNotificationsDrawerOpen = this.handleNotificationsDrawerOpen.bind(this)

    this.state = {
      drawerOpen: true,
      notificationsOpen: true,
      loginDialog: false
    }
  }

  handleSignInClick () {
    this.setState({ loginDialog: true })
  }

  handleSignInClose () {
    this.setState({ loginDialog: false })
  }

  async componentDidMount () {
    const { authState, firestore, firebase } = this.props
    if (authState === 'signedIn') {
      const query = { collection: `users/${firebase.auth().currentUser.uid}/private`, doc: 'settings', storeAs: 'user/settings' }
      const settings = (await firestore.get(query)).data()
      if (settings) {
        UserFormatter.setIsMetric(settings.usesMetricSystem)
      }
    }
  }

  handleNotificationsDrawerOpen () {
    this.setState(({ notificationsOpen }) => ({ notificationsOpen: !notificationsOpen }))
  }

  render () {
    const { authState, authData, onStateChange, auth, isFirstLog } = this.props
    const { children, classes } = this.props
    const { drawerOpen, notificationsOpen, loginDialog } = this.state

    return (
      <div className={classes.root}>
        <AppBar
          color='primary'
          position='absolute'
          className={classNames(
            classes.appBar,
            {
              [classes.appBarShift]: notificationsOpen,
              [classes.appBarShiftNotify]: !notificationsOpen
            }
          )}
        >
          <AppToolbar {...{ auth, authState, authData, onStateChange, onNotificationsDrawerOpen: this.handleNotificationsDrawerOpen }} />
        </AppBar>

        <Drawer
          variant='permanent'
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !drawerOpen && classes.drawerPaperClose
            )
          }}
          open={drawerOpen}
        >
          <SideMenu />

          <div className={classes.footer}>
            <Typography
              variant='subtitle2'
              align='center'
              color='textSecondary'
              component='p'
            >
              {pkg.version} | © 2020 FSS Sp. z o.o.
            </Typography>
          </div>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Dialog open={loginDialog} onClose={this.handleSignInClose}>
            <LoginPage />
          </Dialog>

          {isFirstLog && <StartProvider/>}

          <Page>{children}</Page>
        </main>

        <Drawer
          variant='persistent'
          anchor='right'
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !notificationsOpen && classes.drawerPaperClose
            )
          }}
          open={notificationsOpen}
        >
          <Notifications />
        </Drawer>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    settings: first(state.firestore.ordered[`user/settings`]) || {}
  }
}

const enhanced = compose(
  connect(mapStateToProps),
  withFirebase,
  withFirestore,
  withStyles(styles)
)

export default enhanced(App)
