import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core'
import { withFirestore } from 'react-redux-firebase'
import { Field, reduxForm, change } from 'redux-form'
import NominatimAutoSuggets from '../NominatimAutoSuggets'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { formStyles } from '../../styles'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

class SecondStep extends Component {
  constructor (props) {
    super(props)

    this.renderRadioGroup = this.renderRadioGroup.bind(this)
    this.handleGetSelectedValue = this.handleGetSelectedValue.bind(this)
  }

  renderRadioGroup ({ input, ...rest }) {
    const { classes } = this.props
    return (
      <RadioGroup
        className={classes.radioGroup}
        {...input}
        {...rest}
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      />
    )
  }

  handleGetSelectedValue (hit) {
    const {lat, lon} = hit
    const {firestore, dispatch} = this.props
    const location = new firestore.GeoPoint(Number(lat), Number(lon))
    dispatch(change('remoteLocationForm', 'location', location))
  }

  render () {
    const { handleSubmit, submitting, classes, t } = this.props
    return (
      <div className={classes.root}>
        <div style={{ maxWidth: 500, margin: 'auto' }}>
          <Paper elevation={0} className={classes.paper}>
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Field
                    name='locationString'
                    fullWidth
                    disabled={submitting}
                    getSelectedValue={this.handleGetSelectedValue}
                    component={NominatimAutoSuggets}
                    label={t('Location')}
                    placeholder={t('Enter a location')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormLabel component='legend' className={classes.inputLabel}>{t('Units')}</FormLabel>

                  <Field
                    name='usesMetricSystem'
                    format={val => val ? 'true' : 'false'}
                    parse={val => val === 'true'}
                    disabled={submitting}
                    component={this.renderRadioGroup}>
                    <FormControlLabel
                      value='true'
                      control={<Radio color='default' />}
                      label={t('Metric')}
                    />
                    <FormControlLabel
                      value='false'
                      control={<Radio color='default' />}
                      label={t('Imperial')}
                    />
                  </Field>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>
      </div>
    )
  }
}

SecondStep.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func
}

SecondStep.defaultProps = {
  classes: {},
  handleSubmit: () => {}
}

export default reduxForm({
  form: 'remoteLocationForm'
})(withStyles(formStyles)(withTranslation()(withFirestore(SecondStep))))
