import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import LockIcon from '@material-ui/icons/LockOutlined'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core'
import { Field, reduxForm } from 'redux-form'
import TextField from './TextField'
import * as validators from '../utils/validators'
import PropTypes from 'prop-types'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  wrapper: {
    maxWidth: 320,
    margin: 'auto'
  },
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3)
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  divder: {
    height: 10
  }
})

const validate = values => {
  const errors = {}
  const requiredFields = ['email', 'password']
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  if (values.email) {
    errors.email = validators.email(values.email)
  }

  return errors
}

class LoginForm extends Component {
  render () {
    const { handleSubmit, pristine, submitting, classes } = this.props
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Paper className={classes.paper}>
            {submitting ? (
              <CircularProgress />
            ) : (
              <Avatar className={classes.avatar}>
                <LockIcon />
              </Avatar>
            )}

            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Field
                    name='email'
                    fullWidth
                    required
                    validate={[validators.required, validators.email]}
                    disabled={submitting}
                    autoComplete='email'
                    autoFocus
                    component={TextField}
                    label='Email'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='password'
                    type='password'
                    fullWidth
                    required
                    validate={[validators.required]}
                    disabled={submitting}
                    component={TextField}
                    label='Password'
                  />
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.divder} />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    disabled={pristine || submitting}
                  >
                    Sign in
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>
      </div>
    )
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func
}

LoginForm.defaultProps = {
  classes: {},
  handleSubmit: () => {}
}

export default reduxForm({
  form: 'LoginForm',
  validate
})(withStyles(styles)(LoginForm))
