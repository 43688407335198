
import { combineReducers } from 'redux'
import list from './list'

export default combineReducers({
  firmware: list('firmware', { sorting: { orderBy: 'version' } }),
  users: list('users'),
  tracks: list('tracks', { sorting: { orderBy: 'clientCreateDate' } }),
  drives: list('drives', { sorting: { orderBy: 'serverCreateDate' } }),
  perfAttempts: list('perfAttempts'),
  perfResults: list('perfResults')
})
