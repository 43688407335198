import { actionTypes as firebaseActionTypes } from 'react-redux-firebase'
import { actionTypes as firestoreActionTypes } from 'redux-firestore'

// TODO: test it
const clearFirestoreOnLogout = store => next => action => {
  const returnValue = next(action)
  const { type } = action
  if (type === firebaseActionTypes.AUTH_EMPTY_CHANGE) {
    store.dispatch({ type: firestoreActionTypes.CLEAR_DATA })
  }
  return returnValue
}

export {
  clearFirestoreOnLogout
}
