import { UPDATE_VIEW_FILTERS } from '../../actions/list'

const init = { updateKey: 0 }

export default (resource, defaultState) => (previousState = Object.assign({}, init, defaultState), action = {}) => {
  const { type, meta, payload } = action

  if (!meta || meta.resource !== resource) {
    return previousState
  }

  if (type !== UPDATE_VIEW_FILTERS) {
    return previousState
  }

  return Object.assign({}, payload, { updateKey: previousState.updateKey + 1 })
}
