import React from 'react'
import classNames from 'classnames'
import defaultTo from 'lodash/defaultTo'
import { makeStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import Button from './ButtonComponent'

const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
    minWidth: 320,
    maxHeight: 435
  },
  actions: {
    padding: 15
  },
  success: {
    color: green[600]
  },
  error: {
    color: theme.palette.error.dark
  },
  info: {
    color: blue[500]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}))

function AlertTemplate ({ style, options, message, close }) {

  const classes = useStyles()

  const variantIcon = {
    success: CheckCircleIcon,
    error: ErrorIcon,
    info: InfoIcon
  }

  const variant = options.type || 'info'
  const Icon = variantIcon[options.type]
  const title = options.type

  return (
    <div style={style}>
      <Dialog
        open
        onClose={close}
      >
        <DialogTitle classes={classes.message}>
          {title.toUpperCase()}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            <Icon fontSizeLarge={'large'}
                  className={classNames(classes.icon, classes.iconVariant, classes[variant])}/> {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button label={defaultTo('OK')} onClick={close}/>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AlertTemplate
