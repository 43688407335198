import React from 'react'
import { Switch, Route } from 'react-router'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import red from '@material-ui/core/colors/red'
import firebase from 'firebase/app'
import * as firebaseui from 'firebaseui'
import routes from './constants/routes.json'
import App from './containers/App'
import { withFirebaseAuthenticator } from './firebase'
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic'
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './components/AlertTemplate'
import asyncComponent from './components/AsyncComponent'
const HomePage =  asyncComponent(() => import('./containers/HomePage'))
const ConverterPage =  asyncComponent(() => import('./containers/ConverterPage'))
const ProfilePage =  asyncComponent(() => import('./containers/ProfilePage'))
const VehiclePage =  asyncComponent(() => import('./containers/VehiclePage'))
const FirmwarePage =  asyncComponent(() => import('./containers/FirmwarePage'))
const TracksPage =  asyncComponent(() => import('./containers/TracksPage'))
const DrivesPage =  asyncComponent(() => import('./containers/DrivesPage'))
const UsersPage =  asyncComponent(() => import('./containers/UsersPage'))
const PerformancePage =  asyncComponent(() => import('./containers/PerformancePage'))
const LiveSessionPage =  asyncComponent(() => import('./containers/LiveSessionPage'))
const ReindexPage =  asyncComponent(() => import('./containers/ReindexPage'))
const ImportPage = asyncComponent(() => import('./containers/import'))

// window.LOG_LEVEL = 'DEBUG';

const theme = createMuiTheme({
  palette: {
    default: { main: '#ccc' },
    primary: { main: '#f66a29', contrastText: '#fff' },
    secondary: { main: red[500], contrastText: '#fff' },
    type: 'dark',
    accent: red,
    background: {
      default: '#333'
    }
  },
  status: {
    danger: 'orange'
  },
  typography: {
    useNextVariants: true
  }
})

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false
    },
    new firebase.auth.OAuthProvider('apple.com').providerId,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        // Forces account selection even when one account
        // is available.
        prompt: 'select_account'
      }
    }
  ],
  tosUrl: '/#tos',
  privacyPolicyUrl: function () {
    // window.location.assign('<your-privacy-policy-url>');
  },
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  },
  credentialHelper: firebaseui.auth.CredentialHelper.NONE
}

const AppWithFirebaseAuth = withFirebaseAuthenticator(App, uiConfig)

const NotFound = () => (null)

export default () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <AlertProvider template={AlertTemplate}>
      <BreadcrumbsProvider>
        <AppWithFirebaseAuth>
          <Switch>
            <Route path={routes.HOME} component={HomePage} exact={true} />
            <Route path={routes.ADMIN.FIRMWARE} component={FirmwarePage} />
            <Route path={routes.ADMIN.USERS} component={UsersPage} />
            <Route path={routes.ADMIN.REINDEX} component={ReindexPage} />
            <Route path={routes.PERFORMANCE} component={PerformancePage} />
            <Route path={routes.DRIVES} component={DrivesPage} />
            <Route path={routes.TRACKS} component={TracksPage} />
            <Route path={routes.PROFILE} component={ProfilePage} />
            <Route path={routes.VEHICLE} component={VehiclePage} />
            <Route path={routes.CONVERTER} component={ConverterPage} />
            <Route path={routes.LIVE_SESSION} component={LiveSessionPage} />
            <Route path={routes.IMPORT} component={ImportPage} />
            <Route component={NotFound} />
          </Switch>
        </AppWithFirebaseAuth>
      </BreadcrumbsProvider>
    </AlertProvider>
  </MuiThemeProvider>
)
