/*
 * @Author: Dawid Kuboszek - FSS Sp. z o.o.
 * @Date: 2018-11-09 18:30:14
 * @Last Modified by: Dawid Kuboszek - FSS Sp. z o.o.
 * @Last Modified time: 2018-11-09 19:59:48
 */

import React, { Component } from 'react'
import NotificationsListWithData from '../components/Notifications/ListWithData'

class Notifications extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div className='main-container' data-tid='container'>
        <NotificationsListWithData />
      </div>
    )
  }
}

export default Notifications
