import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { reducer as appStatusReducer } from '../components/AppStatus/store'
import view from './view'

export default (history) => combineReducers({
  appStatus: appStatusReducer,
  view,
  router: connectRouter(history),
  form: formReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer
})
