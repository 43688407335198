import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import CancelIcon from '@material-ui/icons/Cancel'
import BackIcon from '@material-ui/icons/ArrowBackIos'
import DeleteIcon from '@material-ui/icons/Delete'
import ShareIcon from '@material-ui/icons/Share'
import StarIcon from '@material-ui/icons/Star'
import MapIcon from '@material-ui/icons/MapOutlined'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import SettingsIcon from '@material-ui/icons/Settings'
import NextIcon from '@material-ui/icons/NavigateNext'
import PropTypes from 'prop-types'

const styles = theme => ({
  button: {
    margin: theme.spacing(2),
    marginRight: 0
  },
  buttonLink: {
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    opacity: 1
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
})

class ButtonComponent extends PureComponent {
  render () {
    const { classes, onClick, label, icon, color, to, style, disabled, fullWidth, variant, ...rest } = this.props

    const getIcon = () => {
      if (icon !== '') {
        if (icon === 'save') {
          return <SaveIcon className={classes.leftIcon} />
        } else if (icon === 'import') {
          return <SaveAltIcon className={classes.leftIcon} />
        } else if (icon === 'add') {
          return <AddIcon className={classes.leftIcon} />
        } else if (icon === 'cancel') {
          return <CancelIcon className={classes.leftIcon} />
        } else if (icon === 'back') {
          return <BackIcon className={classes.leftIcon} />
        } else if (icon === 'delete') {
          return <DeleteIcon className={classes.leftIcon} />
        } else if (icon === 'share') {
          return <ShareIcon className={classes.leftIcon} />
        } else if (icon === 'favorite') {
          return <StarIcon className={classes.leftIcon} />
        } else if (icon === 'unFavorite') {
          return <StarBorderIcon className={classes.leftIcon} />
        } else if (icon === 'settings') {
          return <SettingsIcon className={classes.leftIcon} />
        } else if (icon === 'map') {
          return <MapIcon className={classes.leftIcon} />
        } else if (icon === 'next') {
          return <NextIcon className={classes.leftIcon} />
        }

        return null
      }

      return null
    }

    const button = <Button fullWidth={fullWidth} disabled={disabled} onClick={onClick} className={classes.button} style={style}
      variant={variant} color={color} {...rest}>
      {getIcon()}
      {label}
    </Button>

    return (
      <div className={classes.buttonLink}>
        {to
          ? <Link to={to} className={classes.link}>{button}</Link>
          : button
        }
      </div>
    )
  }
}

ButtonComponent.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string
}

ButtonComponent.defaultProps = {
  classes: {},
  onClick: () => {},
  label: '',
  icon: '',
  color: 'default',
  to: '',
  style: {},
  disabled: false,
  fullWidth: false,
  variant: 'contained'
}

export default withStyles(styles, { withTheme: true })(ButtonComponent)
