import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dropzone from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import { FormHelperText } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FolderIcon from '@material-ui/icons/Folder'
import Slider from '@material-ui/core/Slider'
import Avatar from '@material-ui/core/Avatar'
import UserIcon from '@material-ui/icons/Person'
import CarIcon from '@material-ui/icons/DirectionsCar'
import { userMessageFromError } from '../utils/formatters'
import { isString } from 'lodash'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  button: {
    height: 56,
    textTransform: 'lowercase'
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(2),
    justifyContent: 'center'
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  slider: {
    padding: '22px 0px'
  },
  avatar: {
    height: 75,
    width: 75
  }
})

class SelectFileComponent extends Component {
  constructor (props) {
    console.log('preview:', props.preview)
    super(props)
    this.state = { files: [], scale: 1 }
  }

  /**
   * @param {Array} files
   */
  setFilePreviewURLs (files) {
    this.revokeFilePreviewURLS()

    if (this.props.preview) {
      this.setState({
        files: files.map(file => ({
          file,
          preview: file.type.match('^image/(png|jpe?g)$') ? URL.createObjectURL(file) : null
        }))
      })
    }
  }

  revokeFilePreviewURLS () {
    const { files } = this.state
    files.forEach(file => {
      URL.revokeObjectURL(file.preview)
    })
  }

  onDrop = (files) => {
    console.log('SelectFileComponent.onDrop', files)

    if (files && files.length) {
      const { input: { onChange } } = this.props

      onChange(files[0])

      this.setFilePreviewURLs(files)
    }
  }

  // Make sure to revoke the data uris to avoid memory leaks
  componentWillUnmount () {
    this.revokeFilePreviewURLS()
  }

  componentDidUpdate (prevProps) {
    if (isString(this.props.input.value) && !isString(prevProps.input.value)) {
      this.setState({ files: [] })
    }
  }

  async avatarChanged () {
    if (this.editor) {
      const { input: { onChange } } = this.props
      const canvas = this.editor.getImage().toDataURL()
      try {
        const blob = await fetch(canvas).then(res => res.blob())
        onChange(blob)
      } catch (e) {
        userMessageFromError(e)
      }
    }
  }

  handleChange = (event, scale) => {
    this.setState({ scale })
  }

  setEditor = (editor) => {
    this.editor = editor
  }

  render () {
    const { t, classes, input: { value, name }, meta: { touched, error }, type, multiple, avatar, car, fullWidth, preview, className, ...rest } = this.props
    console.log('show preview?', preview)

    const { files, scale } = this.state

    let label = this.props.label
    if (!avatar && !car && value) {
      label = value.name
    }

    return (
      <div className={classNames(classes.root, className)} style={fullWidth ? { marginLeft: 0 } : {}}>
        {(avatar || car) && <div className={classes.thumbsContainer}>{files.map(({ file, preview }) => (
          <div key={file.name}>
            {preview &&
            <div>
              <AvatarEditor
                ref={this.setEditor}
                image={preview}
                width={75}
                height={75}
                border={10}
                borderRadius={40}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={scale}
                rotate={0}
                onImageChange={this.avatarChanged}
              />
              <Slider
                classes={{ root: classes.slider }}
                value={scale}
                min={0.25}
                max={2}
                aria-labelledby='label'
                onChange={this.handleChange}
                onDragEnd={this.avatarChanged}
              />
            </div>
            }
          </div>
        ))}
        {files.length < 1 && <Avatar className={classes.avatar} src={value}>
          {avatar && <UserIcon alt={'avatar'} />}
          {car && <CarIcon alt={'car'} />}
        </Avatar>}
        </div>}
        <Dropzone
          style={{ width: fullWidth ? '100%' : 229 }}
          name={name}
          accept={type}
          multiple={multiple}
          onDrop={this.onDrop}
          {...rest}
        >
        {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button className={classes.button} fullWidth variant='outlined'>
                  <FolderIcon className={classes.leftIcon}/>{isDragActive ? `${t('Drop files here')}...` : label}
                </Button>
              </div>
            )
         }}
        </Dropzone>
        {touched && error && <FormHelperText error>{error}</FormHelperText>}
        {!avatar && !car && <aside className={classNames(classes.thumbsContainer, className)}>
          {files.map(({ file, preview }) => (
            <div key={file.name} className={classes.thumb}>
              <div className={classes.thumbInner}>
                {preview ? <img alt='preview' src={preview} className={classes.img} /> : file.name}
              </div>
            </div>
          ))}
        </aside>}
      </div>
    )
  }
}

SelectFileComponent.defaultProps = {
  classes: {},
  className: '',
  meta: {
    touched: false,
    error: false
  },
  preview: false,
  multiple: false,
  fullWidth: false,
  label: '',
  type: '',
  avatar: false,
  car: false
}

SelectFileComponent.propTypes = {
  input: PropTypes.object,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  meta: PropTypes.object,
  fullWidth: PropTypes.bool,
  preview: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string
}

export default withStyles(styles, {withTheme: true})(withTranslation()(SelectFileComponent))
