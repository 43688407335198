import { combineReducers } from 'redux'
import sorting from './sorting'
import filters from './filters'
import { get } from 'lodash'
import { UPDATE_VIEW_PAGINATION, UPDATE_VIEW_SORTING, UPDATE_VIEW_SEARCH, UPDATE_VIEW_FILTERS } from '../../actions/list'

export const init = {
  page: 0,
  limit: 10,
  updateKey: 0,
  search: ''
}

export const pagination = (resource, defaultState) => (previousState = Object.assign({}, init, defaultState), action = {}) => {
  const { type, meta, payload } = action

  if (!meta || meta.resource !== resource) {
    return previousState
  }

  switch (type) {
    case UPDATE_VIEW_PAGINATION: {
      return Object.assign({}, previousState, payload, { updateKey: previousState.updateKey + 1 })
    }
    case UPDATE_VIEW_SORTING: {
      return Object.assign({}, previousState, { page: 0 })
    }
    case UPDATE_VIEW_SEARCH: {
      return Object.assign({}, previousState, payload, { page: 0 })
    }
    case UPDATE_VIEW_FILTERS: {
      return Object.assign({}, previousState, { page: 0 })
    }
    default:
      return previousState
  }
}

export default (resource, defaultState) => combineReducers({
  pagination: pagination(resource, get(defaultState, 'pagination', {})),
  sorting: sorting(resource, get(defaultState, 'sorting', {})),
  filters: filters(resource, get(defaultState, 'filters', {}))
})
