import { connect } from 'react-redux'
import { firestoreConnect, withFirebase, withFirestore, isLoaded } from 'react-redux-firebase'
import firebase from 'firebase/app'
import { compose, withHandlers } from 'recompose'
import { pick, trim,update } from 'lodash'
import { UserFormatter } from '../../utils/formatters'
import { getFormValues } from 'redux-form'
import { uploadPicture } from '../../utils/firebase'

export const withData = compose(
  withFirebase,

  withFirestore,

  withHandlers({
    loadData: props => () => {
      return props.firestore.get(`users/${firebase.auth().currentUser.uid}/private/settings`)
    }
  }),

  withHandlers({
    onSettingsUpdate: props => async (newSettings) => {
      const fields = pick(newSettings, ['locationString', 'location', 'usesMetricSystem', 'eapLevel'])
      await props.firestore.collection(`users/${firebase.auth().currentUser.uid}/private`)
        .doc('settings')
        .set(fields, { merge: true })

      if (fields.usesMetricSystem !== UserFormatter.isMetric()) {
        UserFormatter.setIsMetric(fields.usesMetricSystem)
      }
    },

    onPublicProfileUpdate: props => async (newProfile, file) => {
      const fields = pick(newProfile, ['displayName', 'bio', 'tagline'])

      if (file) {
        const visibility = 'private'
        const path = `user/${props.firebase.auth().currentUser.uid}/${visibility}`
        const uploadOpts = {
          name: (input) => {
            const { name: fileName } = input
            const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName)
            return `avatar${extension && '.'}${extension}`
          }
        }

        const uploadedData = await props.firebase.uploadFile(path, file, undefined, uploadOpts)
        const { uploadTaskSnapshot: { metadata } } = uploadedData
        fields.photoURL = await metadata.ref.getDownloadURL()
      }

      return props.firestore.doc(`users/${firebase.auth().currentUser.uid}`).set(fields, { merge: true })
    },

    onVehicleCreate: props => async values => {
      const ownerId = props.firebase.auth().currentUser.uid
      const fields = pick(values, ['name', 'type', 'year'])
      update(fields, 'name', trim)
      if (values['picture']) {
        fields['photoURL'] = await uploadPicture(props, values['picture'], `user/${ownerId}/private/cars`)
      }
      await props.firestore.add('vehicles',
        {
          ...fields,
          ownerId,
          clientCreateDate: props.firestore.Timestamp.fromDate(new Date())
        })
    }
  }),

  firestoreConnect((props) => {
    if (firebase.auth().currentUser) {
      return [
        { collection: `users/${firebase.auth().currentUser.uid}/private`, doc: 'settings', storeAs: 'user/settings' },
        { collection: `users`, doc: firebase.auth().currentUser.uid, storeAs: 'user/public' }
      ]
    }
  }),

  connect((state) => ({
    profileForm: getFormValues('remoteProfileForm')(state),
    locationForm: getFormValues('remoteLocationForm')(state),
    carForm: getFormValues('remoteCarForm')(state),
    userSettings: state.firestore.ordered[`user/settings`] ? state.firestore.ordered[`user/settings`][0] : {},
    userPublic: state.firestore.ordered[`user/public`] ? state.firestore.ordered[`user/public`][0] : {},
    userVehicle: state.firestore.ordered[`user/vehicles`] ? state.firestore.ordered[`user/vehicles`][0] : {},
    isLoading: !isLoaded(state.firestore.ordered[`user/settings`]) || !isLoaded(state.firestore.ordered[`user/public`]) || !isLoaded(state.firestore.ordered[`user/vehicles`])
  }))
)
