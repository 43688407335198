
export default {
  apiGateway: {
    BASE_URL: 'https://api.redrive.red/api/v1',
    // BASE_URL: 'http://10.0.2.160:8889/api/v1',
    // BASE_URL: 'http://127.0.01:8888/api/v1',
    ID_TOKEN_HEADER_NAME: 'idtoken'
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_QOhV0S222',
    IDENTITY_POOL_ID: 'eu-central-1:b4ed449b-cc6c-4157-9d35-72ba64235311',
    APP_CLIENT_ID: '3aron69ts0b9b5inc2sas2k6gt'
  },
  firebase: {
    apiKey: 'AIzaSyD6i3ZGctKcIP64x7mbmhtJEOoyxAAkr64',
    // authDomain: 'redrive-20a88.firebaseapp.com',
    authDomain: 'app.redrive.red',
    databaseURL: 'https://redrive-20a88.firebaseio.com',
    projectId: 'redrive-20a88',
    storageBucket: 'redrive-eu',
    messagingSenderId: '142889979709'
  },
  rrfConfig: {
    userProfile: 'users',
    useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
    enableLogging: false
  },
  algolia: {
    appId: 'FTLTAR5E6Z',
    apiKey: 'dead2c2e300f8b565c9c17e897353d5b'
  }
}
