import React, { Component } from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import PropTypes from 'prop-types'
import Item from './Item'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

class List extends Component {
  constructor (props) {
    super(props)
    this.handleAction = this.handleAction.bind(this)
  }

  handleAction ({trackId}) {
    if (trackId) {
      this.props.history.replace(`/track/view/${trackId}`)
    }
  }

  render () {
    const { items } = this.props

    return (
      <div>
        {items.map(ntf => {
          const Icon = variantIcon['info']
          return ntf.title && (
            <Item key={ntf.id} item={ntf} Icon={Icon} onDelete={this.props.onDeleteNotify}
              onAction={this.handleAction} />
          )
        })}
      </div>
    )
  }
}

List.propTypes = {
  items: PropTypes.array,
  onDeleteNotify: PropTypes.func
}

List.defaultProps = {
  items: [],
  onDeleteNotify: () => {}
}

export default List
