export const formStyles = theme => ({
  root: {
    flexGrow: 1
  },
  wrapper: {
    maxWidth: 350,
    margin: 'auto'
  },
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    backgroundColor: 'transparent'
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(2)
  },
  avatarContainer: {
    marginTop: -theme.spacing(5)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#f66a29'
  },
  divider: {
    height: 10
  },
  link: {
    fontSize: 10,
    width: '100%',
    '&:hover': {
      fontWeight: 'bold',
      textDecoration: 'underline',
      background: 'transparent'
    }
  },
  linkContainer: {
    padding: 0
  },
  radioGroup: {
    flexDirection: 'row'
  },
  inputAdornment: {
    cursor: 'pointer'
  },
  inputLabel: {
    fontSize: 12
  }
})

export const modalStyles = theme => ({
  paper: {
    position: 'absolute',
    top: 'calc(50% - 150px)',
    right: 'calc(50% - 250px)',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4)
  },
  button: {
    margin: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
})
