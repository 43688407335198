const { getDefaultFormatter, default: UserFormatter } = require('../classes/UserFormatter')

const dateFormatter = (dateOrTimestamp, withTime = false) => {
  let date = dateOrTimestamp
  if (typeof dateOrTimestamp.toDate === 'function') {
    date = dateOrTimestamp.toDate()
  }
  let d = new Date(date).toLocaleString()
  if (!withTime) {
    const index = d.lastIndexOf(',')
    d = d.slice(0, index)
  }
  return d === 'Invalid Date' ? '' : d
}

/**
 * @param value
 * @param precision
 * @return {string}
 */
const speedFormatter = (value, precision) => getDefaultFormatter().stringFromSpeedMps(value, precision)

/**
 * @param value
 * @param precision
 * @return {string}
 */
const distanceFormatter = (value, precision) => getDefaultFormatter().stringFromDistanceMeters(value, precision)

/**
 * @param {Number} duration
 * @param {String} durationUnit
 * @param {Number} decimals
 * @return {String}
 */
const durationFormatter = (duration, durationUnit = 'ms', decimals = 2) => {
  if (durationUnit === 'ms') {
    const milliseconds = parseInt((duration % 1000) / 10)
    let seconds = parseInt((duration / 1000) % 60)
    let minutes = parseInt((duration / (1000 * 60)) % 60)
    let hours = parseInt((duration / (1000 * 60 * 60)) % 24)

    const ps = require('lodash').padStart

    const hs = ps(hours.toString(), 2, '0')
    const ms = ps(minutes.toString(), 2, '0')
    const ss = ps(seconds.toString(), 2, '0')

    if (hours > 0) {
      return `${hs}:${ms}:${ss}.${milliseconds}`
    } else {
      return `${ms}:${ss}.${milliseconds}`
    }
  } else if (durationUnit === 's') {
    return duration.toFixed(decimals) + ' ' + durationUnit
  }
  return duration.toString()
}

/**
 * @param elevation
 * @param precision
 * @return {string}
 */
const elevationFormatter = (elevation, precision = 2) => {
  return isNaN(elevation) ? elevation : elevation.toFixed(precision) + ' %'
}

/**
 * @param address
 * @return {string}
 */
const locationFormatter = (address = null) => {
  if (address) {
    const { city = '', state = '', country = '' } = address
    const _compact = require('lodash').compact
    return _compact([city, state, country]).join(', ')
  }
  return ''
}

/**
 * @param {Number} eapLevel
 * @return {string}
 */
const eapLevelFormatter = eapLevel => {
  if (eapLevel === 0) {
    return 'Public'
  } else if (eapLevel === 1) {
    return 'EAP level 1'
  } else if (eapLevel === 2) {
    return 'EAP level 2'
  } else {
    return ''
  }
}

const userMessageFromError = error => {
  return error.message
}

module.exports = {
  UserFormatter,
  getDefaultFormatter,
  dateFormatter,
  speedFormatter,
  distanceFormatter,
  durationFormatter,
  elevationFormatter,
  locationFormatter,
  eapLevelFormatter,
  userMessageFromError
}
