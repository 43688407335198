import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import mapValues from 'lodash/mapValues'
import merge from 'lodash/merge'
import pl from '../assets/locales/pl/translations'
import plTags from '../assets/locales/pl/tags'
import enTags from '../assets/locales/en/tags'

const options = {
  lng: 'pl',
  fallbackLng: 'pl',
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  }
}

options.resources = {
  pl: { translations: merge(pl, plTags) },
  en: { translations: merge(mapValues(pl, (value, key) => key), enTags) }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options)

export default i18n
