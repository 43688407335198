import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import config from '../config'
import firebase from 'firebase/app'
import Routes from '../Routes'
import '../i18n/i18n'

export default class Root extends Component {
  render () {
    const { store, history } = this.props
    const rrfProps = {
      firebase,
      config: config.rrfConfig,
      dispatch: store.dispatch,
      createFirestoreInstance
    }

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <Routes />
          </ReactReduxFirebaseProvider>
        </ConnectedRouter>
      </Provider>
    )
  }
}
