import React, { Component } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import DashboardIcon from '@material-ui/icons/Dashboard'
import TransformIcon from '@material-ui/icons/Transform'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import BuildIcon from '@material-ui/icons/Build'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import HistoryIcon from '@material-ui/icons/History'
import DockIcon from '@material-ui/icons/Dock'
import RssFeedIcon from '@material-ui/icons/RssFeed'
import PeopleIcon from '@material-ui/icons/PeopleAlt'
import CloudIcon from '@material-ui/icons/CloudQueue'
import Collapse from '@material-ui/core/Collapse'
import { withRouter } from 'react-router'
import firebase from 'firebase/app'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core'

const logoSrc = require('../assets/32x32.png')

const navigationProps = (history, location, path) => {
  let selected = location.pathname.indexOf(path) === 0
  if (path === '/') {
    selected = location.pathname === path
  }

  if (history && location) {
    return {
      selected,
      onClick: () => history.push(path)
    }
  }
  return {}
}

const styles = theme => ({
  icon: {
    height: 24,
    width: 24,
    textAlign: 'center',
    fontSize: 19
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nav: {
    marginTop: 6
  }
})

class SideMenu extends Component {
  constructor (props) {
    super(props)
    this.state = { admin: { open: false }, isAdmin: false, importExp: { open: false } }
    this.handleAdminClick = this.handleAdminClick.bind(this)
    this.handleImportClick = this.handleImportClick.bind(this)
  }

  componentDidMount () {
    this.getCurrentUser()
      .then(cu => {
        const upn = cu.email.split('@')
        const email = upn[1]
        if (email === 'fss.cc') {
          this.setState({ isAdmin: true })
        }
      })
      .catch(e => console.log('ERROR: ', e))
  }

  async getCurrentUser () {
    // console.log('get current user', this)
    return firebase.auth().currentUser
  }

  handleAdminClick () {
    const { admin } = this.state
    const state = { admin: { open: !admin.open } }
    this.setState(state)
  }

  handleImportClick () {
    const { importExp } = this.state
    const state = { importExp: { open: !importExp.open } }
    this.setState(state)
  }
  render () {
    const { history, location, t, classes } = this.props
    const { admin, isAdmin, importExp } = this.state
    return (
      <React.Fragment>
        <List component='nav' className={classes.nav}>
          <ListItem>
            <ListItemIcon>
              <img src={logoSrc} alt='logo' />
            </ListItemIcon>
            <ListItemText primary='REDRIVE' />
          </ListItem>
        </List>
        <Divider />
        <List component='nav'>
          <ListItem button {...navigationProps(history, location, '/')}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary='Dashboard' />
          </ListItem>
          <ListItem
            button
            {...navigationProps(history, location, '/converter')}
          >
            <ListItemIcon>
              <TransformIcon />
            </ListItemIcon>
            <ListItemText primary={t('Converter')} />
          </ListItem>
          <ListItem button onClick={this.handleImportClick}>
            <ListItemIcon>
              <SaveAltIcon />
            </ListItemIcon>
            <ListItemText primary={t('Import')} />
            {importExp.open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={importExp.open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItem
                className={classes.nested}
                button
                {...navigationProps(history, location, '/import/track')}
              >
                <ListItemIcon>
                  <Icon className={classNames(classes.icon, 'fa fa-route')} />
                </ListItemIcon>
                <ListItemText primary={t('Track')} />
              </ListItem>

              <ListItem
                className={classes.nested}
                button
                {...navigationProps(history, location, '/import/drive')}
              >
                <ListItemIcon>
                  <Icon className={classNames(classes.icon, 'fa fa-road')} />
                </ListItemIcon>
                <ListItemText primary={t('Drive')} />
              </ListItem>

              <ListItem
                className={classes.nested}
                button
                {...navigationProps(history, location, '/import/performance')}
              >
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary={t('Performance')} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            {...navigationProps(history, location, '/track')}
          >
            <ListItemIcon>
              <Icon className={classNames(classes.icon, 'fa fa-route')} />
            </ListItemIcon>
            <ListItemText primary={t('Tracks')} />
          </ListItem>
          <ListItem
            button
            {...navigationProps(history, location, '/drive')}
          >
            <ListItemIcon>
              <Icon className={classNames(classes.icon, 'fa fa-road')} />
            </ListItemIcon>
            <ListItemText primary={t('Drives')} />
          </ListItem>
          <ListItem
            button
            {...navigationProps(history, location, '/performance/acceleration')}
          >
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary={t('Performance')} />
          </ListItem>

          <ListItem
            button
            {...navigationProps(history, location, '/live_session')}
          >
            <ListItemIcon>
              <RssFeedIcon />
            </ListItemIcon>
            <ListItemText primary={t('Live') + '!'} />
          </ListItem>
          {isAdmin
            ? <div>
              <ListItem button onClick={this.handleAdminClick}>
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary='Admin' />
                {admin.open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={admin.open} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItem
                    className={classes.nested}
                    button
                    {...navigationProps(history, location, '/admin/firmware')}
                  >
                    <ListItemIcon>
                      <DockIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Firmware'} />
                  </ListItem>

                  <ListItem
                    className={classes.nested}
                    button
                    {...navigationProps(history, location, '/admin/user')}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Users')} />
                  </ListItem>

                  <ListItem
                    className={classes.nested}
                    button
                    {...navigationProps(history, location, '/admin/reindex')}
                  >
                    <ListItemIcon>
                      <CloudIcon />
                    </ListItemIcon>
                    <ListItemText primary='Reindex' />
                  </ListItem>

                </List>
              </Collapse>
            </div> : null }
        </List>
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation()(withStyles(styles, { withTheme: true })(SideMenu)))
