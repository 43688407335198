import React from 'react'
import firebase from 'firebase/app'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { actionTypes } from 'redux-firestore'
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose, withHandlers } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import UserIcon from '@material-ui/icons/Person'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Toolbar from '@material-ui/core/Toolbar'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Divider from '@material-ui/core/Divider'
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos'
import NotificationsIcon from '@material-ui/icons/Notifications'
import CarIcon from '@material-ui/icons/DirectionsCar'
import Badge from '@material-ui/core/Badge'
import { NavLink } from 'react-router-dom'
import { Breadcrumbs } from 'react-breadcrumbs-dynamic'
import DialogConfirm from './DialogConfirm'
import UpdateNotification from './AppStatus/UpdateNotification'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  toolbar: {
    whiteSpace: 'nowrap',
    ...theme.mixins.toolbar
  },
  spacer: {
    flex: 'auto'
  },
  username: {
    margin: theme.spacing(2),
    width: 40,
    height: 40,
    background: 'transparent',
    '&:hover': {
      background: 'transparent'
    }
  },
  popper: {
    minWidth: 100
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: 50,
    fontSize: 20
  },
  vehiclesContainer: {
    maxHeight: 500,
    overflowX: 'auto'
  }
})

class AppToolbar extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      vehiclesOpen: false,
      email: ''
    }

    this.signOut = this.signOut.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleVehiclesToggle = this.handleVehiclesToggle.bind(this)
    this.handleVehiclesClose = this.handleVehiclesClose.bind(this)

  }

  componentDidMount () {
    this.getCurrentUserEmail().then(cu => {
      this.setState({ email: cu.displayName, photoURL: cu.photoURL })
    }).catch(e => console.log('ERROR: ', e)
    )
  }

  signOut () {
    this.props.logout()
  }

  handleClose (event) {
    if (this.anchorEl.contains(event.target)) {
      return
    }

    this.setState({ open: false })
  }

  handleToggle () {
    this.setState(state => ({ open: !state.open }))
  }

  handleVehiclesToggle () {
    this.setState(state => ({ vehiclesOpen: !state.vehiclesOpen }))
  }

  handleVehiclesClose (event) {
    if (this.anchorVehiclesEl.contains(event.target)) {
      return
    }

    this.setState({ vehiclesOpen: false })
  }

  async getCurrentUserEmail () {
    // console.log('get current user email', this)
    return firebase.auth().currentUser
  }

  render () {
    const { classes, authState, history, currentUser, t, vehicles } = this.props
    const { open, vehiclesOpen } = this.state
    return (
      <Toolbar variant='dense' className={classes.toolbar}>
        <Breadcrumbs
          separator={<ArrowForwardIcon className={classes.icon} />}
          item={NavLink}
          containerProps={{ style: { fontSize: 36, position: 'relative', zIndex: 1 } }}
          finalItem={'b'}
        />
        <div className={classes.spacer} />
        <UpdateNotification />
        <IconButton
          buttonRef={node => {
            this.anchorVehiclesEl = node
          }}
          onClick={this.handleVehiclesToggle}
        >
          <CarIcon/>
        </IconButton>
        {authState === 'signedIn' &&
        <Popper
          className={classes.popper}
          open={vehiclesOpen}
          anchorEl={this.anchorVehiclesEl}
          transition
          disablePortal
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              id='menu-list-grow'
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper className={classes.vehiclesContainer}>
                <ClickAwayListener onClickAway={this.handleVehiclesClose}>
                  <MenuList>
                    {vehicles.map(vehicle => (
                      <MenuItem
                        key={vehicle.id}
                        onClick={e => {
                          this.handleVehiclesClose(e)
                          history.push(`/vehicle/edit/${vehicle.id}`)
                        }}
                      >
                        {vehicle.name}
                      </MenuItem>
                    ))}
                    {vehicles.length > 0 && <Divider/>}
                    <MenuItem
                      onClick={e => {
                        this.handleVehiclesClose(e)
                        history.push('/vehicle/add')
                      }}
                    >
                      {t('Add vehicle')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>}
        <IconButton
          onClick={this.props.onNotificationsDrawerOpen}
        >
          <Badge badgeContent={100} max={99} color='primary'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        {authState === 'signedIn' ? (
          <React.Fragment>
            <Fab
              className={classes.username}
              buttonRef={node => {
                this.anchorEl = node
              }}
              aria-owns={open ? 'menu-list-grow' : null}
              aria-haspopup='true'
              onClick={this.handleToggle}
            >
              <Avatar src={currentUser.photoURL}>
                <UserIcon alt={'avatar'} />
              </Avatar>
            </Fab>
            <Popper
              className={classes.popper}
              open={open}
              anchorEl={this.anchorEl}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id='menu-list-grow'
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList>
                        <MenuItem
                          onClick={e => {
                            this.handleClose(e)
                            history.push('/profile/public')
                          }}
                        >
                          {t('Profile')}
                        </MenuItem>
                        <MenuItem
                          onClick={e => {
                            this.handleClose(e)
                            history.push('/profile/settings')
                          }}
                        >
                          {t('Settings')}
                        </MenuItem>
                        <MenuItem
                          onClick={e => {
                            this.handleClose(e)
                            history.push('/profile/passwordchange')
                          }}
                        >
                          {t('Change your password')}
                        </MenuItem>
                        <Divider />
                        <DialogConfirm title={t('Logout')}
                                       description={t('Are you sure to logout?')}
                                       confirmText={t('Logout')}
                                       confirmColor={'secondary'}>
                          {confirm => (
                            <MenuItem onClick={confirm(this.signOut)}>{t('Logout')}</MenuItem>
                          )}
                        </DialogConfirm>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        ) : (
          <Button
            variant='contained'
            size='small'
            color='primary'
            onClick={() => {}}
          >
            {t('Sign in')}
          </Button>
        )}
      </Toolbar>
    )
  }
}

const withData = compose(
  connect((state) => ({
    currentUser: state.firebase.profile,
    uid: state.firebase.auth.uid,
    isLoaded: isLoaded(state.firebase.profile),
    isEmpty: isEmpty(state.firebase.profile),
    vehicles: state.firestore.ordered[`user/vehicles`] || [],
    isVehiclesLoaded: isLoaded(state.firestore.ordered[`user/vehicles`])
  })),
  firestoreConnect(props => [{ collection: 'vehicles', where: ['ownerId', '==', props.uid], storeAs: `user/vehicles` }]),
  withHandlers({
    logout: props => () => {
      return props.auth.signOut().then(() => {
        props.dispatch({ type: actionTypes.CLEAR_DATA })
        window.location.reload()
      }).catch(error => {
        console.log(error.message)
      })
    }
  })
)

export default withRouter(withStyles(styles)(withData(withTranslation()(AppToolbar))))
