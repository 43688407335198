import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Autosuggest from 'react-autosuggest'
import { withStyles } from '@material-ui/core/styles'
import * as Nominatim from 'nominatim-browser'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import Divider from '@material-ui/core/Divider'
import InputLabel from '@material-ui/core/InputLabel'

const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1
  },
  container: {
    position: 'relative',
    marginTop: -theme.spacing(2),
    marginBottom: -theme.spacing(1)
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  divider: {
    height: theme.spacing(2)
  },
  inputAdornment: {
    marginRight: -theme.spacing(2)
  },
  menuItem: {
    height: 'auto',
    whiteSpace: 'normal'
  },
  lastMenuItem: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontStyle: 'italic'
  },
  label: {
    fontSize: 12
  }
})

class AutosuggestComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      suggestions: []
    }

    this.onChange = this.onChange.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.renderInputComponent = this.renderInputComponent.bind(this)
    this.renderSuggestion = this.renderSuggestion.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
  }

  onChange (event, { newValue }) {
    this.setState({
      value: newValue
    })
  }

  async onSuggestionsFetchRequested ({ value }) {
    const { lastSuggestion } = this.props
    const suggestions = await Nominatim.geocode({
      'accept-language': 'pl',
      q: value,
      addressdetails: true,
      limit: 5
    })
    if (lastSuggestion) {
      suggestions.push({ ...lastSuggestion, display_name: value })
    }
    this.setState({ suggestions })
  }

  onSuggestionsClearRequested () {
    this.setState({
      suggestions: []
    })
  }

  async onSuggestionSelected (event, { suggestion }) {
    this.props.getSelectedValue(suggestion)
  }

  handleClear () {
    this.setState({
      value: '',
      suggestions: []
    })
  }

  renderInputComponent (inputProps) {
    const { inputRef = () => {}, ref, ...rest } = inputProps
    const { value } = this.state
    const { classes } = this.props
    return (<TextField
      InputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        },
        endAdornment:
  <InputAdornment className={classes.inputAdornment} style={{ visibility: value === '' ? 'hidden' : 'visible' }} position='end'>
    <IconButton
      onClick={this.handleClear}>
      <ClearIcon />
    </IconButton>
  </InputAdornment>
      }}
      fullWidth
      margin='normal'
      {...rest}
    />)
  }

  renderSuggestion (hit, { isHighlighted }) {
    const { classes } = this.props
    const menuItem = []
    if (hit.tooltip) {
      menuItem.push(<Divider key={hit.type + '_divider'} />)
      menuItem.push(<MenuItem key={hit.type} selected={isHighlighted}
        component='p' className={classes.lastMenuItem}>{hit.tooltip + hit.display_name}</MenuItem>)
    } else {
      menuItem.push(<MenuItem key={hit.place_id} selected={isHighlighted}
        component='p' className={classes.menuItem}>{hit.display_name}</MenuItem>)
    }
    return menuItem
  }

  render () {
    const { classes, input, placeholder, variant, label } = this.props
    const { value, suggestions } = this.state
    const inputProps = {
      placeholder,
      variant
    }
    if (input) {
      inputProps['value'] = input.value
      inputProps['onChange'] = (event, { newValue }) => input.onChange(newValue)
    } else {
      inputProps['value'] = value
      inputProps['onChange'] = this.onChange
    }

    return (
      <div>
        {label &&
        <InputLabel className={classes.label}>
          {label}
        </InputLabel>}
        <Autosuggest
          suggestions={suggestions}
          multiSection={false}
          renderInputComponent={this.renderInputComponent}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={hit => hit['display_name']}
          renderSuggestion={this.renderSuggestion}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps}>
              {options.children}
            </Paper>
          )}
          inputProps={inputProps}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
        />
      </div>
    )
  }
}

AutosuggestComponent.propTypes = {
  classes: PropTypes.object,
  getSelectedValue: PropTypes.func,
  lastSuggestion: PropTypes.object,
  placeholder: PropTypes.string,
  variant: PropTypes.string
}

AutosuggestComponent.defaultProps = {
  classes: {},
  getSelectedValue: () => {},
  lastSuggestion: null,
  placeholder: '',
  variant: 'standard'
}

export default withStyles(styles)(AutosuggestComponent)
