import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'

class UpdateNotification extends PureComponent {
  render () {
    const { appStatus: { availableUpdate }, t } = this.props
    if (availableUpdate) {
      return (
        <React.Fragment>
          <Typography variant='caption'>
            <ListItemText>{t('A new version of the application is available!')}</ListItemText>
          </Typography>
          <Button variant='outlined' color='primary' style={{marginLeft: 8}} onClick={() => {
            caches.keys().then(async function (names) {
              await Promise.all(names.map(name => caches.delete(name)))
              window.location.reload()
            })
          }}>{t('Update')}</Button>
        </React.Fragment>
      )
    }
    return null
  }
}

UpdateNotification.propTypes = {
  t: PropTypes.func.isRequired,
  appUpdate: PropTypes.object
}
UpdateNotification.defaultProps = {}

function mapStateToProps (state) {
  return {
    appStatus: state.appStatus
  }
}

export default connect(mapStateToProps)(withTranslation()(UpdateNotification))
