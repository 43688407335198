import * as _ from 'lodash'
class UserFormatterBase {

  constructor () {
    this.stringFromSpeedMps = this.stringFromSpeedMps.bind(this)
  }

  _requireSubclassImpl () {
    throw new Error('To implement in subclass')
  }

  get speedUnit () {
    return this._requireSubclassImpl()
  }

  valueFromSpeedMps () {
    return this._requireSubclassImpl()
  }

  stringFromSpeedMps (speedMps, precision = 0) {
    return `${this.valueFromSpeedMps(speedMps).toFixed(precision)} ${this.speedUnit}`
  }

  stringFromDistanceMeters () {
    this._requireSubclassImpl()
  }

  get altitudeUnit () {
    return this._requireSubclassImpl()
  }

  altitudeValueFromMeters () {
    this._requireSubclassImpl()
  }
}

class UserFormatterMetric extends UserFormatterBase {

  get isMetric () {
    return true
  }

  get speedUnit () {
    return 'km/h'
  }

  valueFromSpeedMps (speedMps) {
    return (speedMps * 3.6)
  }

  stringFromDistanceMeters (distanceMeters, precision = 0) {
    let value = distanceMeters
    let unit = 'm'
    if (!_.isNumber(distanceMeters)) return `-1 ${unit}`
    if (value > 1000) {
      value = (distanceMeters / 1000)
      unit = 'km'
    }
    else {
      precision = 0
    }

    return `${value.toFixed(precision)} ${unit}`
  }

  get altitudeUnit () {
    return 'm'
  }

  altitudeValueFromMeters (meters) {
    return meters
  }
}

class UserFormatterImperial extends UserFormatterBase {
  get isMetric () {
    return false
  }

  get speedUnit () {
    return 'mph'
  }

  valueFromSpeedMps (speedMps) {
    return (speedMps * 2.236936)
  }

  stringFromDistanceMeters (distanceMeters, precision = 2) {
    let value = (distanceMeters * 0.000621371)
    let unit = 'mi'

    if (value < 0.1) {
      value = (value * 5280)
      unit = 'ft'
      precision = 0
    } else if (value > 100) {
      precision = 0
    }

    return `${value.toFixed(precision)} ${unit}`
  }

  get altitudeUnit () {
    return 'ft'
  }

  altitudeValueFromMeters (meters) {
    return (meters * 3.28084)
  }
}

class UserFormatter {
  static defaultFormatter

  /**
   * @return {UserFormatterMetric|UserFormatterImperial}
   */
  static getDefaultFormatter () {
    return UserFormatter.defaultFormatter
  }

  static setDefaultFormatter (formatter) {
    UserFormatter.defaultFormatter = formatter
  }

  static isMetric () {
    return UserFormatter.defaultFormatter.isMetric
  }

  static setIsMetric (isMetric) {
    if (UserFormatter.defaultFormatter.isMetric !== isMetric) {
      UserFormatter.defaultFormatter = isMetric ? new UserFormatterMetric() : new UserFormatterImperial()
    }
  }
}

UserFormatter.defaultFormatter = new UserFormatterMetric()

const getDefaultFormatter = UserFormatter.getDefaultFormatter

export {
  UserFormatter as default,
  UserFormatterMetric,
  UserFormatterImperial,
  getDefaultFormatter,
}