import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core'
import { Field, reduxForm } from 'redux-form'
import TextField from '../TextField'
import SelectFile from '../SelectFileComponent'
import * as validators from '../../utils/validators'
import { formStyles } from '../../styles'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'

const validate = values => {
  const errors = {}
  const requiredFields = ['displayName']
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = <Trans>Required</Trans>
    }
  })

  return errors
}

class FirstStep extends Component {
  render () {
    const { handleSubmit, submitting, classes, t } = this.props
    const hasPreview = true
    return (
      <div className={classes.root}>
        <div style={{ maxWidth: 500, margin: 'auto' }}>
          <Paper elevation={0} className={classes.paper}>
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid container spacing={5}>
                <Grid className={classes.avatarContainer} item xs={12}>
                  <Field
                    name='picture'
                    fullWidth
                    avatar
                    preview={hasPreview}
                    type='image/jpeg, image/png'
                    label={t('Click to select avatar')}
                    component={SelectFile} />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name='displayName'
                    fullWidth
                    required
                    autoFocus
                    validate={[validators.required]}
                    disabled={submitting}
                    component={TextField}
                    label={t('Name')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name='tagline'
                    fullWidth
                    disabled={submitting}
                    component={TextField}
                    label={t('Tagline')}
                    placeholder={t('e.g. your life motto/quote')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name='bio'
                    fullWidth
                    disabled={submitting}
                    component={TextField}
                    label={'Bio'}
                    placeholder={t('Short note about yourself')}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>
      </div>
    )
  }
}

FirstStep.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func
}

FirstStep.defaultProps = {
  classes: {},
  handleSubmit: () => {}
}

export default reduxForm({
  form: 'remoteProfileForm',
  validate
})(withStyles(formStyles)(withTranslation()(FirstStep)))
