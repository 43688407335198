import { firestoreConnect } from 'react-redux-firebase'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import firebase from 'firebase/app'
import List from './List'
import { withHandlers } from 'recompose'
import { userMessageFromError } from '../../utils/formatters'

const ListWithData = compose(
  firestoreConnect((props) => {
    // console.log('ListWithData.firestoreConnect', props)
    if (firebase.auth().currentUser) {
      return [
        { collection: `/notifications/${firebase.auth().currentUser.uid}/list`, orderBy: ['serverCreateDate', 'desc'], limit: 20, storeAs: 'notifications' }
      ]
    }
    return []
  }),
  connect((state, props) => {
    // console.log('ListWithData.connect', props)
    return ({
      uid: state.firebase.auth.uid,
      items: state.firestore.ordered.notifications ? state.firestore.ordered.notifications : []
    })
  }),
  withHandlers({
    onDeleteNotify: props => async id => {
      try {
        await props.firestore.delete(`notifications/${props.uid}/list/${id}`)
      } catch (e) {
        userMessageFromError(e)
      }
    }
  })
)(List)

export default withRouter(ListWithData)
// /notifications/YL8cPgJOlAahPVpLBeZdPskmujd2/list
