import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from '../reducers'
import { clearFirestoreOnLogout } from '../firebase/middleware'

const history = createBrowserHistory()
const rootReducer = createRootReducer(history)
const router = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()
const enhancer = applyMiddleware(sagaMiddleware, clearFirestoreOnLogout, router)

function configureStore (initialState) {
  return createStore(rootReducer, initialState, enhancer)
}

export { configureStore, history }
