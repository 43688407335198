import uuid from 'uuid/v4'

export const uploadPicture = async (props, picture, path) => {
  const uploadOpts = {
    name: (input) => {
      const { name: fileName } = input
      const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName)
      return `${uuid()}${extension && '.'}${extension}`
    }
  }
  const uploadedData = await props.firebase.uploadFile(path, picture, undefined, uploadOpts)
  const { uploadTaskSnapshot: { metadata } } = uploadedData
  return await metadata.ref.getDownloadURL()
}

export const deleteFile = async (props, url) => {
  try {
    const ref = await props.firebase.storage().refFromURL(url)
    await ref.delete()
  } catch (e) {
    console.log('File url not exist.')
  }
}
