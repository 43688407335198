import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from './ButtonComponent'
import { withStyles } from '@material-ui/core'
import defaultTo from 'lodash/defaultTo'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  content: {
    width: '100%',
    minWidth: 320,
    maxHeight: 435
  },
  actions: {
    padding: 15
  }
})

class DialogConfirm extends React.Component {
  state = {
    open: false,
    callback: null,
    loading: false
  }

  show = callback => event => {
    event.preventDefault()

    event = {
      ...event,
      target: {...event.target, value: event.target.value}
    }

    this.setState({
      open: true,
      callback: () => callback(event)
    })
  }

  hide = () => this.setState({open: false, callback: null})

  confirm = async () => {
    this.setState({loading: true})
    await this.state.callback()
    this.setState({loading: false})
    this.hide()
  }

  render () {
    const {classes, confirmText, cancelText, confirmColor, title, description, children, t, content} = this.props
    const {loading} = this.state
    return (
      <React.Fragment>
        {children(this.show)}
        <div>
          <Dialog
            open={this.state.open}
            onClose={this.hide}
          >
            {loading && <LinearProgress/>}
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.content}>
              <DialogContentText>
                {description}
              </DialogContentText>
              {content && content}
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button label={defaultTo(cancelText, t('Cancel'))} onClick={this.hide}/>
              <Button color={defaultTo(confirmColor, 'primary')} label={defaultTo(confirmText, 'OK')} autoFocus
                      onClick={this.confirm}/>
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    )
  }
}

DialogConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  confirmColor: PropTypes.string
}

DialogConfirm.defaultProps = {
  content: null
}

export default withStyles(styles)(withTranslation()(DialogConfirm))
