import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { withTranslation } from 'react-i18next'
import { withData } from './withData'
import { get } from 'lodash'
import FirstStep from './firstStep'
import SecondStep from './secondStep'
import ThirdStep from './thirdStep'

const steps = ['Complete the profile', 'Enter settings', 'Add car']

const styles = theme => ({
  root: {
    width: '100%'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

class StartProvider extends Component {
  constructor (props) {
    super(props)
    this.state = { loading: false, open: false, activeStep: 0 }
    this.handleClose = this.handleClose.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  componentDidUpdate (prevProps) {
    const { userVehicle, isLoading } = this.props

    if (prevProps.isLoading && !isLoading) {
      if (get(userVehicle, 'name')) {
        this.setState({ open: false })
      } else {
        this.setState({ open: true })
      }
    }
  }

  getStepAction = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return this.onSubmitPublic
      case 1:
        return this.onSubmitSettings
      case 2:
        return this.onSubmitCar
      default:
        return () => {}
    }
  }

  getStepContent = (stepIndex) => {
    const { userSettings, userPublic, userVehicle } = this.props
    const enableReinitialize = true

    switch (stepIndex) {
      case 0:
        const publicValues = {
          displayName: get(userPublic, 'displayName', ''),
          picture: get(userPublic, 'photoURL', ''),
          bio: get(userPublic, 'bio', ''),
          tagline: get(userPublic, 'tagline', ''),
          fullname: get(userPublic, 'fullName', '')
        }
        return <FirstStep initialValues={publicValues} enableReinitialize={enableReinitialize} />
      case 1:
        const settingsValues = {
          locationString: get(userSettings, 'locationString', ''),
          location: get(userSettings, 'location', []),
          usesMetricSystem: get(userSettings, 'usesMetricSystem', ''),
          theme: get(userSettings, 'theme', ''),
          eapLevel: get(userSettings, 'eapLevel', '')
        }
        return <SecondStep initialValues={settingsValues} enableReinitialize={enableReinitialize} />
      case 2:
        const vehicleValues = {
          name: get(userVehicle, 'name', ''),
          type: get(userVehicle, 'type', 'car'),
          year: get(userVehicle, 'year', new Date().getFullYear())
        }
        return <ThirdStep initialValues={vehicleValues} enableReinitialize={enableReinitialize}/>
      default:
        return 'Unknown stepIndex'
    }
  }

  handleClose () {
    this.setState({ open: false })
  }

  async handleNext () {
    const { activeStep } = this.state
    try {
      await this.getStepAction(activeStep)()
      if (activeStep === steps.length - 1) {
        this.setState(state => ({ open: !state.open }))
      } else {
        this.setState(state => ({ activeStep: state.activeStep + 1 }))
      }
    } catch (e) {
      console.log('Error: ', e.message)
    }
  }

  handleBack () {
    this.setState(state => ({ activeStep: state.activeStep - 1 }))
  }

  handleReset () {
    this.setState({ activeStep: 0 })
  }

  onSubmitPublic = async () => {
    const values = this.props.profileForm
    const { picture, ...rest } = values
    let avatar
    if (picture.constructor === Blob || picture.constructor === File) {
      avatar = picture
    } else {
      avatar = undefined
    }

    this.setState({ loading: true })
    if (rest['displayName'] && rest['displayName'] !== '') {
      await this.props.onPublicProfileUpdate(rest, avatar)
    } else {
      throw new Error('displayName is required')
    }
    this.setState({ loading: false })
  }

  onSubmitSettings = async () => {
    const values = this.props.locationForm
    this.setState({ loading: true })
    await this.props.onSettingsUpdate(values)
    this.setState({ loading: false })
  }

  onSubmitCar = async () => {
    const values = this.props.carForm
    this.setState({ loading: true })
    if (values['name'] && values['name'] !== '') {
      await this.props.onVehicleCreate(values)
    } else {
      throw new Error('name is required')
    }
    this.setState({ loading: false })
  }

  render () {
    const { classes, t } = this.props
    const { open, activeStep } = this.state

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent dividers>

          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{t(label)}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
                <div>
                  <div className={classes.instructions}>{this.getStepContent(activeStep)}</div>
                  <div>
                    <Button
                      onClick={this.handleClose}
                      className={classes.backButton}
                    >
                      {t('Cancel')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.handleNext}>
                      {activeStep === steps.length - 1 ? t('Finish') : t('Next')}
                    </Button>
                  </div>
                </div>
            </div>
          </div>

        </DialogContent>
      </Dialog>
    )
  }
}

export default withData(withStyles(styles, {withTheme: true})(withTranslation()(StartProvider)))
