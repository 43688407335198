const safeClearTimeout = id => {
  try {
    clearTimeout(id)
  } catch (err) {
    console.error(err)
  }
}

class Timers {
  constructor () {
    /**
     * @type {Set<{id: Number, ts: Number}>}
     * @private
     */
    this._ids = new Set()

    /**
     * @type {number}
     * @private
     */
    this._tickID = setInterval(this._tick.bind(this), 500)

    if (process) {
      process.once('exit', () => this.destroy())
    }
  }

  /**
   * @param {Function} fn
   * @param {Number} [delay]
   * @return {number | *}
   */
  runLater (fn, delay = 0) {
    const entry = {
      id: setTimeout(fn, delay),
      ts: Date.now() + delay
    }
    this._ids.add(entry)
    return entry.id
  }

  /**
   * automatically cleans the delay queue
   * @private
   */
  _tick () {
    const now = Date.now()

    this._ids.forEach(entry => {
      if ((now - entry.ts) > 10) {
        safeClearTimeout(entry.id)
        this._ids.delete(entry)
      }
    })
  }

  cancelAll () {
    this._ids.forEach(entry => {
      safeClearTimeout(entry.id)
    })

    this._ids.clear()
  }

  destroy () {
    clearInterval(this._tickID)
    this.cancelAll()
  }
}

const globalTimers = new Timers()

/**
 * @param {Function} fn
 * @param {Number} delay
 * @return {*}
 */
const runLater = (fn, delay) => {
  return globalTimers.runLater(fn, delay)
}

const pause = (delay) => new Promise(resolve => {
  return runLater(() => resolve(true), delay)
})

export { Timers, runLater, pause }
