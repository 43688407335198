export const required = value => (value == null ? 'Required' : undefined)
export const number = value => value && isNaN(value) ? 'Must be a number' : undefined
export const password = value => value && !/^(?=.*[a-z])(?=.*[0-9])(?=.{6,})/.test(value) ? 'Invalid password format' : undefined
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined
export const semver = value =>
  value && !/^\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(value)
    ? 'Invalid version format'
    : undefined
