import React from 'react'
import firebase from 'firebase/app'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import ContentWrapper from '../components/ContentWrapper'

const { build: { productName: appName } } = require('../../package.json')

function withFirebaseAuthenticator (Comp, uiConfig) {
  return class extends React.Component {
    // Listen to the Firebase Auth state and set the local state.
    componentDidMount () {
      this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => {
          let authState = user ? 'signedIn' : 'signedOut'
          let isFirstLog = false

          if (user) {
            isFirstLog = user.metadata.lastSignInTime === user.metadata.creationTime
            console.log('isFirstLog? ', isFirstLog)
            if (!user.emailVerified) {
              authState = 'verifyEmail'
            }
          }

          this.setState({ isSignedIn: !!user, authState, isFirstLog, initializing: false })
        }
      )
    }

    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount () {
      this.unregisterAuthObserver()
    }

    constructor (props) {
      super(props)

      this.handleAuthStateChange = this.handleAuthStateChange.bind(this)

      this.state = {
        authState: props.authState || null,
        authData: props.authData || null,
        initializing: true,
        isFirstLog: false
      }
    }

    handleAuthStateChange (state, data) {
      this.setState({ authState: state, authData: data })
    }

    render () {
      const { initializing, isSignedIn, authState, isFirstLog } = this.state

      if (initializing) {
        return (
          <ContentWrapper>
            <h1>Launching {appName}</h1>
            <CircularProgress />
          </ContentWrapper>
        )
      } else {
        if (!isSignedIn) {
          return (
            <ContentWrapper>
              <div>
                <h1>{appName}</h1>
                <p>It's your time - Catch it!</p>
              </div>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </ContentWrapper>
          )
        }

        if (authState === 'verifyEmail') {
          return <ContentWrapper>
            <h1>{appName}</h1>
            <p>Please verify your email</p>
            <span onClick={() => firebase.auth().currentUser.sendEmailVerification()} >Resend</span> | <span onClick={() => window.location.reload()} >Refresh</span> | <span onClick={() => firebase.auth().signOut()} >Sign out</span>

          </ContentWrapper>
        }

        return (
          <Comp
            {...this.props}
            auth={firebase.auth()}
            authState={authState}
            isFirstLog={isFirstLog}
          />
        )
      }
    }
  }
}

export {
  withFirebaseAuthenticator
}
