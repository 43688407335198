import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'

const styles = theme => ({
  snackbar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '100%',
    background: 'transparent',
    flexDirection: 'column-reverse',
    color: '#fff'
  },
  message: {
    width: '100%'
  },
  icon: {
    fontSize: 20,
    color: '#fff'
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  messageContainer: {
    whiteSpace: 'pre-wrap',
    cursor: 'pointer'
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  iconClose: {
    height: 0,
    padding: 0,
    top: theme.spacing(1/2)
  }
})

class Item extends Component {
  render () {
    const { classes, item: { id, title, body, data }, Icon } = this.props

    return (
      <SnackbarContent
        classes={{ root: classes.snackbar, message: classes.message }}
        message={
          <div onClick={() => this.props.onAction(data)}
            className={classes.messageContainer}>
            <Grid container>
              {Icon && <Icon className={classNames(classes.icon, classes.iconVariant)} />}
              <Typography className={classes.title} variant='body2'>
                {title}
              </Typography>
            </Grid>
            <Typography variant='caption'>
              {body}
            </Typography>
          </div>}
        action={[
          <IconButton
            key='close'
            className={classes.iconClose}
            onClick={() => this.props.onDelete(id)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    )
  }
}

Item.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    data: PropTypes.object
  }),
  Icon: PropTypes.object,
  onDelete: PropTypes.func,
  onAction: PropTypes.func
}

Item.defaultProps = {
  classes: {},
  item: {
    id: '',
    title: '',
    body: '',
    data: {}
  },
  onDelete: () => {},
  onAction: () => {}
}

export default withStyles(styles)(Item)
