import React from 'react'
import { render } from 'react-dom'
// import { AppContainer } from 'react-hot-loader'
import Root from './containers/Root'
import './app.global.css'
import config from './config'
// import { configureStore, history } from './store/configureStore.dev'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import * as serviceWorker from './serviceWorker'
import * as appStatus from './components/AppStatus/store'
import algoliasearch from 'algoliasearch/lite'

firebase.initializeApp(config.firebase)
firebase.functions()
// firebase.firestore.setLogLevel('debug')

export const searchClient = algoliasearch(config.algolia.appId, config.algolia.apiKey)

const { configureStore, history } = require('./store/configureStore')
const store = configureStore()

render(
  <Root store={store} history={history} />,
  document.getElementById('root')
)

serviceWorker.register({
  onUpdate: registration => {
    store.dispatch({
      type: appStatus.types.UPDATE_NOTIFICATION,
      payload: {
        text: 'New version available!',
        showReloadButton: true
        // onClick: () => worker.postMessage({action: 'skipWaiting'})
      }
    })
  }
}) // serviceWorker.unegister()
