import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core'
import { withFirestore } from 'react-redux-firebase'
import MenuItem from '@material-ui/core/MenuItem'
import { Field, reduxForm } from 'redux-form'
import TextField from '../TextField'
import { formStyles } from '../../styles'
import PropTypes from 'prop-types'
import { Trans, withTranslation } from 'react-i18next'
import SelectFile from '../SelectFileComponent'
import * as validators from '../../utils/validators'
import { trim, inRange, map } from 'lodash'

const options = {
  car: 'Car',
  bike: 'Bike',
  atv: 'ATV/Quad',
  snowmobile: 'Snowmobile',
  jetpack: 'Jetpack'
}

const validate = values => {
  const errors = {}
  const requiredFields = ['name', 'type', 'year']
  requiredFields.forEach(field => {
    if (!trim(values[field])) {
      errors[field] = <Trans>Required</Trans>
    }
  })

  if (values.year && !inRange(values.year, 1900, new Date().getFullYear() + 1)) {
    errors.year = <Trans>Invalid year format</Trans>
  }

  return errors
}

class ThirdStep extends Component {

  render () {
    const { handleSubmit, submitting, classes, t } = this.props
    const hasPreview = true

    return (
      <div className={classes.root}>
        <div style={{ maxWidth: 500, margin: 'auto' }}>
          <Paper elevation={0} className={classes.paper}>
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid container spacing={5}>
                <Grid className={classes.avatarContainer} item xs={12}>
                  <Field
                    name='picture'
                    car
                    fullWidth
                    preview={hasPreview}
                    type='image/jpeg, image/png'
                    label={t('Click to select car image')}
                    component={SelectFile} />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name='name'
                    required
                    validate={[validators.required]}
                    autoFocus
                    fullWidth
                    component={TextField}
                    label={t('Name')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name='type'
                    required
                    validate={[validators.required]}
                    label={t('Type')}
                    component={TextField}
                    fullWidth
                    select
                    disabled={submitting}>
                    {map(options, (value, key) => <MenuItem key={key} value={key}>{t(value)}</MenuItem>)}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name='year'
                    required
                    validate={[validators.required]}
                    fullWidth
                    component={TextField}
                    type="number"
                    parse={parseInt}
                    label={t('Year')}
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>
      </div>
    )
  }
}

ThirdStep.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func
}

ThirdStep.defaultProps = {
  classes: {},
  handleSubmit: () => {}
}

export default reduxForm({
  form: 'remoteCarForm',
  validate
})(withStyles(formStyles)(withTranslation()(withFirestore(ThirdStep))))
