import React, { Component } from 'react'
import LoginForm from '../components/LoginForm'
import { pause } from '../classes/Timers'

export default class LoginPage extends Component {
  constructor (props) {
    super(props)

    this.state = { loading: false }

    this.onSubmit = this.onSubmit.bind(this)
  }

  /**
   * // TODO implement
   * @param values
   * @return {Promise<void>}
   */
  async onSubmit (values) {
    this.setState({ loading: true })
    await pause(250)
    this.setState({ loading: false })
  }

  render () {
    const { loading } = this.state
    return <LoginForm loading={loading} onSubmit={this.onSubmit} />
  }
}
