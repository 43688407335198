import React from 'react'
import { withStyles } from '@material-ui/core'

const styles = () => ({
  root: {
    flexGrow: 1,
    padding: 10
  },
  wrapper: {
    paddingTop: 20,
    maxWidth: 350,
    margin: 'auto',
    textAlign: 'center'
  }
})

class ContentWrapper extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ContentWrapper)
