import { UPDATE_VIEW_SORTING } from '../../actions/list'

const init = { orderBy: '', direction: 'desc', updateKey: 0 }

export default (resource, defaultState) => (previousState = Object.assign({}, init, defaultState), action = {}) => {
  const { type, meta, payload } = action

  if (!meta || meta.resource !== resource) {
    return previousState
  }

  if (type !== UPDATE_VIEW_SORTING) {
    return previousState
  }

  return Object.assign({}, previousState, payload, { updateKey: previousState.updateKey + 1 })
}
