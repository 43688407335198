const types = (prefix =>
  ({
    UPDATE_NOTIFICATION: `${prefix}/UPDATE_NOTIFICATION`
  }))('APP_STATUS')

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_NOTIFICATION: {
      return { ...state, availableUpdate: action.payload }
    }
    default:
      return state
  }
}

export {
  types,
  reducer
}
