import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'

const styles = () => ({})

class Page extends Component {
  render () {
    const { children } = this.props
    return (
      <div className='main-container'>
        {children}
      </div>
    )
  }
}

export default withStyles(styles)(Page)
